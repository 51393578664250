import React from 'react';
import { Router } from '@reach/router';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import { StateContext } from './hooks/state-context';
import { useAuthentication } from './hooks/authentication';
import AppToolbar from './components/Toolbar';
import AppLogin from './components/Login';
import AppPageLoader from './components/PageLoader';
import { SnackbarUtilsConfigurator }  from './components/Snackbar';
import Home from './components/Home';
import Ingredients from './components/Ingredients';

export default function App() {
  const { initialized, loggedIn, user } = useAuthentication();
  const classes = useStyles();

  if (!initialized) {
    return <AppPageLoader />
  }

  return (
    <StateContext.Provider value={{ user }}>
      <SnackbarProvider maxSnack={3}>
        <SnackbarUtilsConfigurator />
        <AppToolbar />
        {!loggedIn ? (
          <AppLogin />
        ) : (
          <Grid container
                spacing={0}
                alignItems="center"
                justify="center">
            <Grid item xs={12} md={8} lg={6} xl={5} className={classes.container}>
              <Router>
                <Home path="/" />
                <Ingredients path="recipe/:recipeId/ingredients" />
              </Router>
            </Grid>
          </Grid>
        )}
      </SnackbarProvider>
    </StateContext.Provider>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      // marginTop: 20,
      padding: 20,
    },
  }),
);
