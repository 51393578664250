import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, TextField, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

import { useStateValue } from '../../hooks/state-context';
import { Game } from '../../models/game';
import Snackbar from '../Snackbar';
import { gameService } from '../../services/game';

interface IGameEditParams {
  selectedGame: Game,
  setSelectedGame: React.Dispatch<React.SetStateAction<Game>>,
  close: () => void,
}

export default function GameEdit ({selectedGame, setSelectedGame, close}: IGameEditParams) {
  const classes = useStyles();
  const { user } = useStateValue();
  const [name, setName] = useState('');
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    setName(selectedGame.name);
  }, [selectedGame]);

  const cleanInput = (): string => {
    return name.trim();
  };

  const validate = (name: string): boolean => {
    if (!name) {
      Snackbar.warning('Name is required');
      return false;
    }

    return true;
  };

  const save = async () => {
    setBusy(true);
    try {
      const name = cleanInput();
      if (!validate(name)) return;
      if (selectedGame.name === name) return close();

      const changingNameCase = selectedGame.name.toLowerCase() === name.toLowerCase();
      const game = Game.from({...selectedGame, name});

      try {
        if (!changingNameCase) {
          const exists = await gameService.exists(game);
          if (exists) {
            Snackbar.warning('A game with this name already exists');
            return;
          }
        }

        await gameService.updateGame(game, user?.email!);
      } catch {
        return;
      }

      setSelectedGame(prevState => {
        prevState.update(game);
        return prevState;
      });
    } finally {
      setBusy(false);
    }

    close();
  };

  const cancel = () => {
    close();
  };

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Edit Game</h4>
      <div className={classes.controlContainer}>
        <TextField className={classes.gameName} label="Name" placeholder="Unicorn Farts" value={name} onChange={e => setName(e.target.value)} disabled={busy} />
        <div className={classes.toolbar}>
          <Tooltip title="Save">
            <span className={classes.progressWrapper}>
              <IconButton onClick={save} color="inherit" disabled={busy}>
                <DoneTwoToneIcon />
              </IconButton>
              {busy && <CircularProgress size={30} className={classes.progress} />}
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <span>
              <IconButton onClick={cancel} color="inherit" disabled={busy}>
                <CloseTwoToneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
      padding: 5,
      background: '#ededed',
    },
    title: {
      margin: 0,
    },
    controlContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    gameName: {
      width: '100%',
    },
    toolbar: {
      marginLeft: 'auto',
      whiteSpace: 'nowrap',
    },
    progressWrapper: {
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -15,
      marginLeft: -15,
    },
  }),
);
