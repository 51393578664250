import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

import { Game } from '../../models/game';
import { gameService } from '../../services/game';
import Snackbar from '../Snackbar';

interface IGameDeleteParams {
  setGames: React.Dispatch<React.SetStateAction<Game[]>>,
  selectedGame: Game,
  setSelectedGame: React.Dispatch<React.SetStateAction<Game>>,
  close: () => void,
}

export default function GameDelete ({setGames, selectedGame, setSelectedGame, close}: IGameDeleteParams) {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);

  const confirm = async () => {
    setBusy(true);

    try {
      await gameService.deleteGame(selectedGame.id);
      Snackbar.success(`"${selectedGame.name}" deleted`);
    } catch {
      setBusy(false);
      return;
    }

    const gameId = selectedGame.id;
    setSelectedGame(Game.createEmpty());

    setGames(prevState => {
      const games = [...prevState];
      const index = games.findIndex(x => x.id === gameId);
      games.splice(index, 1);
      return games;
    });

    close();
  };

  const cancel = () => {
    close();
  };

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Delete Game</h4>
      <div className={classes.controlContainer}>
        <p>Delete "{selectedGame.name}" and all of its recipes?</p>
        <div className={classes.toolbar}>
          <Tooltip title="Delete">
            <span className={classes.progressWrapper}>
              <IconButton onClick={confirm} color="inherit" disabled={busy}>
                <DoneTwoToneIcon />
              </IconButton>
              {busy && <CircularProgress size={30} className={classes.progress} />}
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <span>
              <IconButton onClick={cancel} color="inherit" disabled={busy}>
                <CloseTwoToneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
      padding: 5,
      background: '#ededed',
    },
    title: {
      margin: 0,
    },
    controlContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    toolbar: {
      marginLeft: 'auto',
      whiteSpace: 'nowrap',
    },
    progressWrapper: {
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -15,
      marginLeft: -15,
    },
  }),
);
