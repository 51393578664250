import Snackbar from '../components/Snackbar';

import { db } from './firebase';
import { Recipe } from '../models/recipe';

export class RecipeService {
  async getByGame(gameId: string): Promise<Recipe[]> {
    try {
      const actions = await db.collection('recipes')
        .where('gameId', '==', gameId)
        .get();

      return actions.docs.map(x => new Recipe(x));
    } catch (error) {
      Snackbar.error('Unable to get games');
      console.error(error);
      return [];
    }
  }

  async exists(recipe: Recipe): Promise<boolean> {
    try {
      const actions = await db.collection('recipes')
        .where('gameId', '==', recipe.gameId)
        .where('name', '==', recipe.name)
        .limit(1)
        .get();

      return !actions.empty;
    } catch (error) {
      Snackbar.error('Unable to check if recipe name exists');
      console.error(error);
      throw error;
    }
  }

  async get(recipeId: string): Promise<Recipe | null> {
    try {
      const doc = await db.collection('recipes')
        .doc(recipeId)
        .get();

      return doc.exists ? new Recipe(doc) : null;
    } catch (error) {
      Snackbar.error('Unable to get recipe');
      console.error(error);
      throw error;
    }
  }

  async addRecipe(recipe: Recipe, updatedBy: string): Promise<any> {
    const doc = recipe.toFirebaseDoc(updatedBy);
    const ref = db.collection('recipes').doc();

    try {
      await ref.set(doc);
    } catch (error) {
      Snackbar.error('Unable to save recipe');
      console.error(error);
      throw error;
    }

    recipe.update(Object.assign({
      id: ref.id,
    }, doc));
  }

  async updateRecipe(recipe: Recipe, updatedBy: string): Promise<any> {
    const doc = recipe.toFirebaseDoc(updatedBy);

    try {
      await db.collection('recipes')
        .doc(recipe.id)
        .set(doc, { merge: true });
    } catch (error) {
      Snackbar.error('Unable to save changes');
      console.error(error);
      throw error;
    }

    recipe.update(doc);
  }

  async deleteRecipe(recipeId: string): Promise<any> {
    try {
      await db.collection('recipes')
        .doc(recipeId)
        .delete();
    } catch (error) {
      Snackbar.error('Unable to delete recipe');
      console.error(error);
      throw error;
    }
  }
}

export const recipeService = new RecipeService();
