import React from 'react';
import { Button, Card, CardContent, Typography, makeStyles, createStyles, Grid } from '@material-ui/core';

import { login } from '../hooks/authentication';

export default function AppLogin() {
  const classes = useStyles();

  return (
    <Grid container
          spacing={0}
          alignItems="center"
          justify="center"
          className={classes.root}>
      <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
        <Card variant="outlined">
          <CardContent className={classes.card}>
            <Typography variant="h6" className={classes.title}>
              Authorization Required
            </Typography>
            <Button variant="contained" color="primary" onClick={login}>Please Login</Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 64px)',
    },
    title: {
      marginBottom: 30,
    },
    card: {
      minHeight: 210,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
