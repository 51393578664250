import Snackbar from '../components/Snackbar';

import { db, faas } from './firebase';
import { Game } from '../models/game';

export class GameService {
  async getAll(): Promise<Game[]> {
    try {
      const actions = await db.collection('games')
        .orderBy('name')
        .get();
      return actions.docs.map(x => new Game(x));
    } catch (error) {
      Snackbar.error('Unable to get games');
      console.error(error);
      return [];
    }
  }

  async exists(game: Game): Promise<boolean> {
    try {
      const actions = await db.collection('games')
        .where('name', '==', game.name)
        .limit(1)
        .get();

      return !actions.empty;
    } catch (error) {
      Snackbar.error('Unable to check if game name exists');
      console.error(error);
      throw error;
    }
  }

  async addGame(game: Game, updatedBy: string): Promise<any> {
    const doc = game.toFirebaseDoc(updatedBy);
    const ref = db.collection('games').doc();

    try {
      await ref.set(doc);
    } catch (error) {
      Snackbar.error('Unable to save game');
      console.error(error);
      throw error;
    }

    game.update(Object.assign({
      id: ref.id,
    }, doc));
  }

  async updateGame(game: Game, updatedBy: string): Promise<any> {
    const doc = game.toFirebaseDoc(updatedBy);

    try {
      await db.collection('games')
        .doc(game.id)
        .set(doc, { merge: true });
    } catch (error) {
      Snackbar.error('Unable to save changes');
      console.error(error);
      throw error;
    }

    game.update(doc);
  }

  async deleteGame(gameId: string): Promise<any> {
    try {
      await db.collection('games')
        .doc(gameId)
        .delete();
    } catch (error) {
      Snackbar.error('Unable to delete game');
      console.error(error);
      throw error;
    }

    const callDeleteRelease = faas.httpsCallable('deleteRelease');
    try {
      await callDeleteRelease({ gameId });
    } catch (error) {
      Snackbar.error('The game was deleted but its releases were not');
      console.error(error);
      throw error;
    }
  }

  async deployGame(gameId: string): Promise<number> {
    const callDeployGame = faas.httpsCallable('deployGame');
    try {
      const response = await callDeployGame({ gameId });
      return response.data;
    } catch (error) {
      Snackbar.error(`Unable to deploy game: ${error.message || 'unexpected error'}`);
      console.error(error);
      throw error;
    }
  }
}

export const gameService = new GameService();
