import React, { useContext } from 'react';

interface IStateContext {
  user: firebase.User | null,
}

export const StateContext = React.createContext<IStateContext>({
  user: null,
});

export const useStateValue = () => useContext(StateContext);
