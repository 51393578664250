import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

import { Game } from '../../models/game';
import { gameService } from '../../services/game';
import Snackbar from '../Snackbar';

interface IGameDeployParams {
  selectedGame: Game,
  setSelectedGame: React.Dispatch<React.SetStateAction<Game>>,
  close: () => void,
}

export default function GameDeploy ({selectedGame, setSelectedGame, close}: IGameDeployParams) {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);

  const confirm = async () => {
    setBusy(true);

    let release: number;
    try {
      release = await gameService.deployGame(selectedGame.id);
      Snackbar.success(`"${selectedGame.name}" deployment successful`);
    } catch {
      setBusy(false);
      return;
    }

    setSelectedGame(prevState => {
      prevState.release = release;
      return prevState;
    });

    close();
  };

  const cancel = () => {
    close();
  };

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Deploy Game</h4>
      <div className={classes.controlContainer}>
        <p>Are you sure you want to deploy "{selectedGame.name}"? The last deployment was: {selectedGame.releaseDate}. </p>
        <div className={classes.toolbar}>
          <Tooltip title="Deploy">
            <span className={classes.progressWrapper}>
              <IconButton onClick={confirm} color="inherit" disabled={busy}>
                <DoneTwoToneIcon />
              </IconButton>
              {busy && <CircularProgress size={30} className={classes.progress} />}
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <span>
              <IconButton onClick={cancel} color="inherit" disabled={busy}>
                <CloseTwoToneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
      padding: 5,
      background: '#ededed',
    },
    title: {
      margin: 0,
    },
    controlContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    toolbar: {
      marginLeft: 'auto',
      whiteSpace: 'nowrap',
    },
    progressWrapper: {
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -15,
      marginLeft: -15,
    },
  }),
);
