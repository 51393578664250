export const firebaseConfig = {
  apiKey: "AIzaSyCDKuy9I3EJds3XNDoZyeJ_OWeT55FLstE",
  authDomain: "gamers-cookbook-2toad.firebaseapp.com",
  databaseURL: "https://gamers-cookbook-2toad.firebaseio.com",
  projectId: "gamers-cookbook-2toad",
  storageBucket: "gamers-cookbook-2toad.appspot.com",
  messagingSenderId: "776229184163",
  appId: "1:776229184163:web:662a72f1f9a934fc691516",
  measurementId: "G-JGDH0H4LYH"
};
