import { firestore } from 'firebase/app';

import { Entity } from './entity';

export class Recipe extends Entity {
  gameId!: string;

  constructor(doc?: firestore.DocumentSnapshot<firestore.DocumentData>) {
    super(doc);

    const data = doc?.data();
    if (data) this.update(data);
  }

  static from(data: any): Recipe {
    const recipe = new Recipe();
    recipe.update(data);
    return recipe;
  }

  update(data: any): void {
    super.update(data);

    this.gameId = data.gameId;
  }

  toFirebaseDoc(author: string): any {
    const doc = super.toFirebaseDoc(author);

    return Object.assign(doc, {
      name: this.name,
      gameId: this.gameId,
    });
  }
}
