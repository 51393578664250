import { firestore } from 'firebase/app';
import moment from 'moment-timezone';

export class Entity {
  id!: string;
  name!: string;
  updatedBy!: string;
  updatedOn!: Date;

  constructor(doc?: firestore.DocumentSnapshot<firestore.DocumentData>) {
    if (doc) this.id = doc.id;
  }

  update(data: any): void {
    if (typeof data.id === 'string') this.id = data.id;

    this.name = data.name;
    this.updatedBy = data.updatedBy;
    this.updatedOn = typeof data.updatedOn === 'number'
      ? Entity.unixTimestampToDate(data.updatedOn)
      : data.updatedOn;
  }

  toFirebaseDoc(author: string): any {
    return {
      updatedBy: author,
      updatedOn: Entity.dateToUnixTimestamp(new Date()),
    };
  }

  static unixTimestampToDate(unixTimestamp: number): Date {
    return moment.unix(unixTimestamp).toDate();
  }

  static unixTimestampToFormatted(unixTimestamp: string, timeZone: string, format: string): string {
    return moment.unix(Number(unixTimestamp)).tz(timeZone).format(format);
  }

  static dateToUnixTimestamp(date: Date | string): number {
    return moment(date).unix();
  }
}
