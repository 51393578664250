import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';

import Games from './games/Games';
import Recipes from './Recipes';
import { Game } from '../models/game';

export default function Home(_: RouteComponentProps) {
  const [selectedGame, setSelectedGame] = useState<Game>(Game.createEmpty());

  return (
    <>
      <Games selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
      <Recipes game={selectedGame} />
    </>
  );
}
