import { firestore } from 'firebase/app';

import { Entity } from './entity';

export class Ingredient extends Entity {
  recipeId!: string;
  quantity!: number;
  location!: string;

  constructor(doc?: firestore.DocumentSnapshot<firestore.DocumentData>) {
    super(doc);

    const data = doc?.data();
    if (data) this.update(data);
  }

  static from(data: any): Ingredient {
    const ingredient = new Ingredient();
    ingredient.update(data);
    return ingredient;
  }

  update(data: any): void {
    super.update(data);

    this.recipeId = data.recipeId;
    this.quantity = data.quantity;
    this.location = data.location;
  }

  toFirebaseDoc(author: string): any {
    const doc = super.toFirebaseDoc(author);

    return Object.assign(doc, {
      recipeId: this.recipeId,
      name: this.name,
      quantity: this.quantity,
      location: this.location,
    });
  }
}
