import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, TextField, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

import { useStateValue } from '../../hooks/state-context';
import { Game } from '../../models/game';
import Snackbar from '../Snackbar';
import { gameService } from '../../services/game';

interface IGameAddParams {
  setGames: React.Dispatch<React.SetStateAction<Game[]>>,
  setSelectedGame: React.Dispatch<React.SetStateAction<Game>>,
  close: () => void,
}

export default function GameAdd ({setGames, setSelectedGame, close}: IGameAddParams) {
  const classes = useStyles();
  const { user } = useStateValue();
  const [name, setName] = useState('');
  const [busy, setBusy] = useState(false);

  const cleanInput = () => {
    setName(name.trim());
  };

  const validate = (): boolean => {
    if (!name) {
      Snackbar.warning('Name is required');
      return false;
    }

    return true;
  };

  const save = async () => {
    setBusy(true);
    try {
      cleanInput();
      if (!validate()) return;

      const game = Game.from({
        name,
        release: null,
      });

      try {
        const exists = await gameService.exists(game);
        if (exists) {
          Snackbar.warning('A game with this name already exists');
          return;
        }

        await gameService.addGame(game, user?.email!);
      } catch {
        return;
      }

      setGames((prevState) => {
        const games = [...prevState];
        games.push(game);
        return games;
      });

      setSelectedGame(game);
    } finally {
      setBusy(false);
    }

    close();
  };

  const cancel = () => {
    close();
  };

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Add Game</h4>
      <div className={classes.controlContainer}>
        <TextField className={classes.gameName} label="Name" placeholder="Unicorn Farts" value={name} onChange={e => setName(e.target.value)} disabled={busy} />
        <div className={classes.toolbar}>
          <Tooltip title="Save">
            <span className={classes.progressWrapper}>
              <IconButton onClick={save} color="inherit" disabled={busy}>
                <DoneTwoToneIcon />
              </IconButton>
              {busy && <CircularProgress size={30} className={classes.progress} />}
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <span>
              <IconButton onClick={cancel} color="inherit" disabled={busy}>
                <CloseTwoToneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
      padding: 5,
      background: '#ededed',
    },
    title: {
      margin: 0,
    },
    controlContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    gameName: {
      width: '100%',
    },
    toolbar: {
      marginLeft: 'auto',
      whiteSpace: 'nowrap',
    },
    progressWrapper: {
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -15,
      marginLeft: -15,
    },
  }),
);
