import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, MenuItem, makeStyles, Theme, createStyles, Tooltip, IconButton, Menu, Divider } from '@material-ui/core';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import LoadingOverlay from 'react-loading-overlay';

import { gameService } from '../../services/game';
import { Game } from '../../models/game';
import GameSelect from './GameSelect';
import GameAdd from './GameAdd';
import GameEdit from './GameEdit';
import GameDelete from './GameDelete';
import GameDeploy from './GameDeploy';

interface IGameParams {
  selectedGame: Game,
  setSelectedGame: React.Dispatch<React.SetStateAction<Game>>,
}

enum ViewMode {
  Select,
  Add,
  Edit,
  Deploy,
  Delete,
}

export default function Games ({selectedGame, setSelectedGame}: IGameParams) {
  const classes = useStyles();
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Select);
  const [games, setGames] = useState<Game[]>([]);
  const [gameMenuAnchorEl, setGameMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const isGameMenuOpen = Boolean(gameMenuAnchorEl);

  useEffect(() => {
    (async () => {
      try {
        const games = await gameService.getAll();
        setGames(games);
        if (!games.length) return;

        const gameId = localStorage.getItem('selectedGameId');
        const game = games.find(x => x.id === gameId);
        if (!game) { return; }

        setSelectedGame(game);
      } finally {
        setLoading(false);
      }
    })();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedGame.id) return;
    localStorage.setItem('selectedGameId', selectedGame.id);
  }, [selectedGame]);

  const openGameMenu = (event: any) => {
    setGameMenuAnchorEl(event.currentTarget);
  };

  const closeGameMenu = () => {
    setGameMenuAnchorEl(null);
  };

  const handleChangeView = (mode: ViewMode) => {
    setViewMode(mode);
    closeGameMenu();
  };

  const resetViewMode = () => {
    setViewMode(ViewMode.Select);
  };

  return (
    <LoadingOverlay active={loading} spinner>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.controlContainer}>
            <Typography variant="h6">
              Game
            </Typography>
            <Tooltip title="Manage Games" className={classes.gameSettings}>
              <IconButton onClick={openGameMenu} color="inherit">
                <SettingsTwoToneIcon />
              </IconButton>
            </Tooltip>
            <Menu anchorEl={gameMenuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={isGameMenuOpen}
                  onClose={closeGameMenu}>
              <MenuItem onClick={() => handleChangeView(ViewMode.Add)}>
                <AddCircleTwoToneIcon className={classes.menuIcon} />
                Add Game
              </MenuItem>
              <MenuItem onClick={() => handleChangeView(ViewMode.Edit)} disabled={!selectedGame.id}>
                <EditTwoToneIcon className={classes.menuIcon} />
                Edit Game
              </MenuItem>
              <MenuItem onClick={() => handleChangeView(ViewMode.Delete)} disabled={!selectedGame.id}>
                <DeleteTwoToneIcon className={classes.menuIcon} />
                Delete Game
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleChangeView(ViewMode.Deploy)} disabled={!selectedGame.id}>
                <SendTwoToneIcon className={classes.menuIcon} />
                Deploy Game
              </MenuItem>
            </Menu>
          </div>
          {viewMode === ViewMode.Select &&
            <GameSelect games={games}
                        selectedGame={selectedGame}
                        setSelectedGame={setSelectedGame} />
          }
          {viewMode === ViewMode.Add &&
            <GameAdd setGames={setGames}
                    setSelectedGame={setSelectedGame}
                    close={resetViewMode} />
          }
          {viewMode === ViewMode.Edit &&
            <GameEdit selectedGame={selectedGame}
                      setSelectedGame={setSelectedGame}
                      close={resetViewMode} />
          }
          {viewMode === ViewMode.Delete &&
            <GameDelete setGames={setGames}
                        selectedGame={selectedGame}
                        setSelectedGame={setSelectedGame}
                        close={resetViewMode} />
          }
          {viewMode === ViewMode.Deploy &&
            <GameDeploy selectedGame={selectedGame}
                        setSelectedGame={setSelectedGame}
                        close={resetViewMode} />
          }
        </CardContent>
      </Card>
    </LoadingOverlay>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(1),
    },
    controlContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    gameSettings: {
      marginLeft: 'auto',
    },
    menuIcon: {
      marginRight: 10,
    },
  }),
);
