import { firestore } from 'firebase/app';
import moment from 'moment';

import { Entity } from './entity';

export class Game extends Entity {
  release!: number;

  get releaseDate(): string {
    return this.release
      ? moment.unix(this.release).format('MM/DD/YYYY h:mm:ss A')
      : 'Never';
  }

  constructor(doc?: firestore.DocumentSnapshot<firestore.DocumentData>) {
    super(doc);

    const data = doc?.data();
    if (data) this.update(data);
  }

  static from(data: any): Game {
    const game = new Game();
    game.update(data);
    return game;
  }

  static createEmpty(): Game {
    return Game.from({id: ''});
  }

  update(data: any): void {
    super.update(data);

    this.release = data.release;
  }

  toFirebaseDoc(author: string): any {
    const doc = super.toFirebaseDoc(author);

    return Object.assign(doc, {
      name: this.name,
      release: this.release,
    });
  }
}
