import React, { useEffect, useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { navigate } from '@reach/router';
import LoadingOverlay from 'react-loading-overlay';

import { recipeService } from '../services/recipe';
import { Recipe } from '../models/recipe';
import Snackbar from './Snackbar';
import { useStateValue } from '../hooks/state-context';
import { Game } from '../models/game';

interface IRecipesParams {
  game: Game,
}

export default function Recipes ({game}: IRecipesParams) {
  const { user } = useStateValue();
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    { title: 'Name', field: 'name', defaultSort: 'asc' },
  ] as Array<Column<Recipe>>;

  useEffect(() => {
    if (!game.id) {
      setRecipes([]);
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const recipes = await recipeService.getByGame(game.id);
        setRecipes(recipes);
      } finally {
        setLoading(false);
      }
    })();
  }, [game.id]);

  const handleRowClick = (_event: any, rowData: any) => {
    const recipe = rowData as Recipe;
    navigate(`recipe/${recipe.id}/ingredients`);
  };

  const cleanInput = (recipe: Recipe) => {
    recipe.name = recipe.name && recipe.name.trim();
  };

  const validate = (recipe: Recipe): boolean => {
    if (!recipe.name) {
      Snackbar.warning('Name is required');
      return false;
    }

    return true;
  };

  const addRecipe = (newData: Recipe) =>
    new Promise(async (resolve, reject) => {
      cleanInput(newData);
      if (!validate(newData)) return reject();

      const recipe = Recipe.from({
        gameId: game.id,
        name: newData.name,
      });

      try {
        const exists = await recipeService.exists(recipe);
        if (exists) {
          Snackbar.warning('A recipe with this name already exists');
          return reject();
        }

        await recipeService.addRecipe(recipe, user?.email!);
      } catch {
        return reject();
      }

      resolve();

      setRecipes((prevState) => {
        const recipes = [...prevState];
        recipes.push(recipe);
        return recipes;
      });
    });

  const updateRecipe = (newData: Recipe, oldRecipe: Recipe | undefined) =>
    new Promise(async (resolve, reject) => {
      cleanInput(newData);
      if (!validate(newData)) return reject();
      if (newData.name === oldRecipe?.name) return resolve();

      const changingNameCase = newData.name.toLowerCase() === oldRecipe?.name.toLowerCase();
      const recipe = Recipe.from(newData);

      try {
        if (!changingNameCase) {
          const exists = await recipeService.exists(recipe);
          if (exists) {
            Snackbar.warning('A recipe with this name already exists');
            return reject();
          }
        }

        await recipeService.updateRecipe(recipe, user?.email!);
      } catch {
        return reject();
      }

      resolve();

      setRecipes(prevState => {
        const recipes = [...prevState];
        const index = recipes.findIndex(x => x.id === recipe.id);
        recipes[index] = recipe;
        return recipes;
      });
    });

  const deleteRecipe  = (recipe: Recipe) =>
    new Promise(async (resolve, reject) => {
      try {
        await recipeService.deleteRecipe(recipe.id);
      } catch {
        return reject();
      }

      resolve();

      setRecipes(prevState => {
        const recipes = [...prevState];
        const index = recipes.findIndex(x => x.id === recipe.id);
        recipes.splice(index, 1);
        return recipes;
      });
    });

  return (
    <LoadingOverlay active={loading} spinner>
      <MaterialTable
        title="Recipes"
        columns={columns}
        data={recipes}
        options={{
          addRowPosition: 'first',
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No recipes',
            editRow: {
              deleteText: 'Delete this recipe and all of its ingredients?',
            },
          },
        }}
        onRowClick={handleRowClick}
        editable={{
          onRowAdd: addRecipe,
          onRowUpdate: updateRecipe,
          onRowDelete: deleteRecipe,
        }}
      />
    </LoadingOverlay>
  );
}
