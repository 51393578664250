import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

import { firebaseConfig } from '../firebase-config';

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const faas = firebase.functions();
