import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import MaterialTable, { Column } from 'material-table';
import { navigate } from '@reach/router';
import LoadingOverlay from 'react-loading-overlay';

import { ingredientService } from '../services/ingredient';
import { Ingredient } from '../models/ingredient';
import Snackbar from './Snackbar';
import { useStateValue } from '../hooks/state-context';
import { Recipe } from '../models/recipe';
import { recipeService } from '../services/recipe';

type TParams = { recipeId: string };

export default function Ingredients(props: RouteComponentProps<TParams>) {
  const { user } = useStateValue();
  const [recipe, setRecipe] = useState<Recipe | null>();
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    { title: 'Name', field: 'name', defaultSort: 'asc' },
    { title: 'Quantity', field: 'quantity', type: 'numeric', headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
    { title: 'Location', field: 'location' },
  ] as Array<Column<Ingredient>>;

  useEffect(() => {
    if (!props.recipeId) {
      navigate('/');
      return;
    }

    (async () => {
      try {
        const [recipe, ingredients] = await Promise.all<Recipe | null, Ingredient[]>([
          recipeService.get(props.recipeId!),
          ingredientService.getByRecipe(props.recipeId!),
        ]);

        if (!recipe) {
          Snackbar.warning('No recipe found for the specified ID');
          return;
        }

        setRecipe(recipe);
        setIngredients(ingredients);
      } finally {
        setLoading(false);
      }
    })();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const cleanInput = (ingredient: Ingredient) => {
    ingredient.name = ingredient.name && ingredient.name.trim();
    ingredient.location = ingredient.location && ingredient.location.trim();
  };

  const validate = (ingredient: Ingredient): boolean => {
    if (!ingredient.name) {
      Snackbar.warning('Name is required');
      return false;
    }

    if (!ingredient.quantity || ingredient.quantity < 1) {
      Snackbar.warning('Quantity must be greater than 0');
      return false;
    }

    if (!ingredient.location) {
      Snackbar.warning('Location is required');
      return false;
    }

    return true;
  };

  const addIngredient = (newData: Ingredient) =>
    new Promise(async (resolve, reject) => {
      cleanInput(newData);
      if (!validate(newData)) return reject();

      const ingredient = Ingredient.from({
        recipeId: props.recipeId,
        name: newData.name,
        quantity: newData.quantity,
        location: newData.location,
      });

      try {
        const exists = await ingredientService.exists(ingredient);
        if (exists) {
          Snackbar.warning('An ingredient with this name already exists');
          return reject();
        }

        await ingredientService.addIngredient(ingredient, user?.email!);
      } catch {
        return reject();
      }

      resolve();

      setIngredients((prevState) => {
        const ingredients = [...prevState];
        ingredients.push(ingredient);
        return ingredients;
      });
    });

  const hasChanges = (newData: Ingredient, oldIngredient: Ingredient | undefined): boolean =>  {
    return newData.name !== oldIngredient?.name
      || newData.quantity !== oldIngredient?.quantity
      || newData.location !== oldIngredient?.location;
  };

  const updateIngredient = (newData: Ingredient, oldIngredient: Ingredient | undefined) =>
    new Promise(async (resolve, reject) => {
      cleanInput(newData);
      if (!validate(newData)) return reject();
      if (!hasChanges(newData, oldIngredient)) return resolve();

      const changingNameCase = newData.name.toLowerCase() === oldIngredient?.name.toLowerCase();
      const ingredient = Ingredient.from(newData);

      try {
        if (!changingNameCase) {
          const exists = await ingredientService.exists(ingredient);
          if (exists) {
            Snackbar.warning('An ingredient with this name already exists');
            return reject();
          }
        }

        await ingredientService.updateIngredient(ingredient, user?.email!);
      } catch {
        return reject();
      }

      resolve();

      setIngredients(prevState => {
        const ingredients = [...prevState];
        const index = ingredients.findIndex(x => x.id === ingredient.id);
        ingredients[index] = ingredient;
        return ingredients;
      });
    });

  const deleteIngredient  = (ingredient: Ingredient) =>
    new Promise(async (resolve, reject) => {
      try {
        await ingredientService.deleteIngredient(ingredient.id);
      } catch {
        return reject();
      }

      resolve();

      setIngredients(prevState => {
        const ingredients = [...prevState];
        const index = ingredients.findIndex(x => x.id === ingredient.id);
        ingredients.splice(index, 1);
        return ingredients;
      });
    });

  return (
    <LoadingOverlay active={loading} spinner>
      <MaterialTable
        title={`Ingredients - ${recipe ? recipe.name : ''}`}
        columns={columns}
        data={ingredients}
        options={{
          addRowPosition: 'first',
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No ingredients',
            editRow: {
              deleteText: 'Delete this ingredient?',
            },
          },
        }}
        editable={{
          onRowAdd: addIngredient,
          onRowUpdate: updateIngredient,
          onRowDelete: deleteIngredient,
        }}
      />
    </LoadingOverlay>
  );
}
