import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';

import { Game } from '../../models/game';

interface IGameParams {
  games: Game[],
  selectedGame: Game,
  setSelectedGame: React.Dispatch<React.SetStateAction<Game>>,
}

export default function GameSelect ({games, selectedGame, setSelectedGame}: IGameParams) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    const game = games.find(x => x.id === event.target.value);
    game && setSelectedGame(game);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="game-select-label">Selected Game</InputLabel>
      <Select id="game-select"
              labelId="game-select-label"
              value={selectedGame.id}
              onChange={handleChange}
              disabled={!games.length}>
        {games.map((game, index) =>
          <MenuItem key={index} value={game.id}>{game.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 140,
    },
  }),
);
