import Snackbar from '../components/Snackbar';

import { db } from './firebase';
import { Ingredient } from '../models/ingredient';

export class IngredientService {
  async getByRecipe(recipeId: string): Promise<Ingredient[]> {
    try {
      const actions = await db.collection('ingredients')
        .where('recipeId', '==', recipeId)
        .get();

      return actions.docs.map(x => new Ingredient(x));
    } catch (error) {
      Snackbar.error('Unable to get ingredients');
      console.error(error);
      return [];
    }
  }

  async exists(ingredient: Ingredient): Promise<boolean> {
    try {
      const actions = await db.collection('ingredients')
        .where('recipeId', '==', ingredient.recipeId)
        .where('name', '==', ingredient.name)
        .limit(1)
        .get();

      return !actions.empty;
    } catch (error) {
      Snackbar.error('Unable to check if ingredient name exists');
      console.error(error);
      throw error;
    }
  }

  async addIngredient(ingredient: Ingredient, updatedBy: string): Promise<any> {
    const doc = ingredient.toFirebaseDoc(updatedBy);
    const ref = db.collection('ingredients').doc();

    try {
      await ref.set(doc);
    } catch (error) {
      Snackbar.error('Unable to save ingredient');
      console.error(error);
      throw error;
    }

    ingredient.update({...doc, id: ref.id});
  }

  async updateIngredient(ingredient: Ingredient, updatedBy: string): Promise<any> {
    const doc = ingredient.toFirebaseDoc(updatedBy);

    try {
      await db.collection('ingredients')
        .doc(ingredient.id)
        .set(doc, { merge: true });
    } catch (error) {
      Snackbar.error('Unable to save changes');
      console.error(error);
      throw error;
    }

    ingredient.update(doc);
  }

  async deleteIngredient(ingredientId: string): Promise<any> {
    try {
      await db.collection('ingredients')
        .doc(ingredientId)
        .delete();
    } catch (error) {
      Snackbar.error('Unable to delete ingredient');
      console.error(error);
      throw error;
    }
  }
}

export const ingredientService = new IngredientService();
