import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { navigate } from '@reach/router';

import { useStateValue } from '../hooks/state-context';
import { logout, useAuthentication } from '../hooks/authentication';
import { Tooltip } from '@material-ui/core';

export default function AppToolbar() {
  const classes = useStyles();
  const { user } = useStateValue();
  const { loggedIn } = useAuthentication();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleHome = () => {
    navigate('/');
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Home">
            <IconButton onClick={handleHome} className={classes.appIcon} color="inherit">
              <LocalLibraryTwoToneIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6">
            Gamer's Cookbook
          </Typography>
          {loggedIn && (
            <div className={classes.currentUser}>
              {user?.displayName}
              <Tooltip title="Profile">
                <IconButton onClick={handleMenu} color="inherit">
                  <AccountCircleTwoToneIcon />
                </IconButton>
              </Tooltip>
              <Menu id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    onClose={handleClose}>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appIcon: {
      marginRight: theme.spacing(2),
    },
    currentUser: {
      flex: 1,
      textAlign: 'right',
    },
  }),
);
