import React from 'react';
import { auth } from 'firebase/app';
import 'firebase/auth';

export const useAuthentication = () => {
  const [state, setState] = React.useState(() => {
    const user = auth().currentUser;
    return {
      initialized: false,
      loggedIn: Boolean(user),
      user,
    };
  });

  function onChange(user: any) {
    if (user && !user.email.match(/^.*@(2toad\.com|pierce\.io)$/i)) {
      logout();
      return;
    }

    setState({ initialized: true, loggedIn: Boolean(user), user });
  }

  React.useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  return state;
};

const provider = new auth.GoogleAuthProvider();

export const login = () => {
  auth().signInWithPopup(provider);
};

export const logout = () => {
  auth().signOut();
};
